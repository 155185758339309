import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa"; // Import icons
import projectone from "../../img/projectone.png";
import projecttwo from "../../img/projecttwo.png";
import projectthree from "../../img/projectthree.png";

import { themeContext } from "../../Context";

const projects = [
  {
    name: "Youtube clone",
    image: projectone,
    github: "https://github.com/sristhilamichhane/youtube_clone",
    live: "https://srimedia.netlify.app",
  },
  {
    name: "Tic-tac-toe",
    image: projectthree,
    github: "https://github.com/sristhilamichhane/Tic-Tac-Toe",
    live: "https://tic-tac-toe-woad-nu.vercel.app/",
  },
  {
    name: "Admin-dashboard",
    image: projecttwo,
    github: "https://github.com/sristhilamichhane/dashboard",
    live: "https://dasboard-syncfusion.netlify.app",
  },
];

const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{ color: darkMode ? "white" : "" }}>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        {projects.map((project, index) => (
          <SwiperSlide key={index}>
            <div className="project-slide">
              <img src={project.image} alt={project.name} />
              <div className="project-links" style={{ gap: 3 }}>
                <a
                  href={project.github}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGithub />
                </a>
                <a
                  href={project.live}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaExternalLinkAlt />
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Portfolio;
